import React, { useEffect, useState } from 'react'
import { Offcanvas } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import showdown from 'showdown'
import { useHttp } from '../../../hooks/http.hook'
import { useMessage } from '../../../hooks/message.hook'

export const RoadMapEpicDetails = ({ options, onClose }) => {
  const [changed, setChanged] = useState(false)
  const { request, error, clearError } = useHttp()
  const auth = useSelector(state => state.auth)

  const [projectTarget, setProjectTarget] = useState('')
  const [mainTasks, setMainTasks] = useState('')
  const message = useMessage()
  const [AItext, setAItext] = useState('')
  const [displayedText, setDisplayedText] = useState('')

  const [lang, setLang] = useState('ru')

  const converter = new showdown.Converter()
  const html = converter.makeHtml(displayedText) // используем отображаемый текст






  const saveTechTask = async (id) => {
    try {


      let prompt = `Привет, представь, что ты архитектор программного обеспечения с большим стажем.\n Ты должен без лишних слов предоставить рекомендации, от одной до трёх рекомендаций по формированию технического задания, максимум 140 слов, без заголовка, только нумерованный список рекомендаций , цвет рекомендаций - #2E3E73, представь все красиво в markdown\n Язык на котором будут рекомендации: ${lang}\n  Вот задание:\n`


      const gemini = await request('/api/gemini/generate', 'POST', { prompt, lang, epicSummary: options.epic.summary, projectTarget, mainTasks }, { authorization: 'Bearer ' + auth.token })




      // const url = 'http://162.248.225.190:5088/generate'

      // const response = await fetch(url, {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({
      //     text: `${prompt}\n Техническое задание на реализацию\n ${options.epic.summary}\n Требования \n Цель проекта \n ${projectTarget}\n Основные задачи\n${mainTasks}`,  // Замените на необходимое значение
      //   }),
      // })




      // const target = await fetch(url, {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({
      //     text: `Переведи на: ${lang} без лишних слов следующий текст:\n ${projectTarget}`,  // Замените на необходимое значение
      //   }),
      // })




      // const task = await fetch(url, {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({
      //     text: `Переведи на: ${lang} без лишних слов следующий текст\n: ${mainTasks}`,  // Замените на необходимое значение
      //   }),
      // })


      console.log("gemini", gemini)



      // const gemini = await response.json()
      // const targetTEXT = await target.json()
      // const taskTEXT = await task.json()

      console.log("gemini", gemini)
      setAItext(gemini.gemini.text)
      setProjectTarget(gemini.targetTEXT.text)
      setMainTasks(gemini.taskTEXT.text)

      const data = await request('/api/techtask/save', 'POST', { id, projectTarget: gemini.targetTEXT.text, mainTasks: gemini.taskTEXT.text, aiText: gemini.gemini.text, lang }, { authorization: 'Bearer ' + auth.token })
      message(data.message)

      // if (!gemini) {
      //   throw new Error(`Ошибка`)
      // }

    } catch (e) {
      console.log(e)
    }
  }




  const dictionary = {

    title: {
      ru: 'Техническое задание на реализацию',
      en: 'Technical task on implementation',
      fr: 'Tâche technique sur l’implémentation',
      de: 'Technische Aufgabe für die Implementierung',
      es: 'Tarea técnica en la implementación',
    },

    requirements: {
      ru: 'Требования',
      en: 'Requirements',
      fr: 'Exigences',
      de: 'Vorraussetzungen',
      es: 'Requisitos',
    },

    target: {
      ru: 'Цель проекта',
      en: 'Project goal',
      fr: 'Objectif du projet',
      de: 'Projektziel',
      es: 'Objetivo del proyecto',
    },

    tasks: {
      ru: 'Основные задачи',
      en: 'Main tasks',
      fr: 'Tâches principales',
      de: 'Hauptaufgaben',
      es: 'Tareas principales',

    },

    buttonText: {
      ru: 'Внести изменения',
      en: 'Save changes',
      fr: 'Enregistrer les modifications',
      de: 'Anderungen speichern',
      es: 'Guardar cambios',
    },

    lang: {
      ru: 'язык',
      en: 'language',
      fr: 'langue',
      de: 'Sprache',
      es: 'lengua',

    },

    suggestions: {
      ru: 'Предложения от рекомендательной системы',
      en: 'Suggestions from the recommendation system',
      fr: 'Suggestions de l’outil de recommandation',
      de: 'Vorschläge vom Empfehlungssystem',
      es: 'Sugerencias del sistema de recomendación',
    }


  }








  // useEffect(() => {
  //   const displayText = (text, delay = 10) => {
  //     let index = 0

  //     const interval = setInterval(() => {
  //       const currentText = text.substring(0, index) // Вырезаем часть текста
  //       setDisplayedText(currentText) // Устанавливаем отображаемую часть текста
  //       index++

  //       if (index > text.length) {
  //         clearInterval(interval)
  //       }
  //     }, delay)
  //   }

  //   setDisplayedText('') // Сбрасываем текст перед новой анимацией
  //   displayText(AItext)
  // }, [AItext])





  useEffect(() => {
    const displayText = (text, delay = 10) => {
      let index = 0
      setDisplayedText(text) // Устанавливаем отображаемую часть текста

    }

    displayText(AItext)
  }, [AItext])




  const handleClose = (changed) => {
    setProjectTarget('')
    setMainTasks('')
    setAItext('')
    onClose(changed)
  }


  useEffect(() => {
    (async () => {
      try {
        const data = await request('/api/techtask/get', 'POST', { id: options.epic.id }, { authorization: 'Bearer ' + auth.token })
        setProjectTarget(data.techtask.projectTarget)
        setMainTasks(data.techtask.mainTasks)
        setAItext(data.techtask.aiText)
        setLang(data.techtask.lang)
      } catch (e) {
        console.log(e)
      }
    })()
  }, [options.showdowncount, options.epic.id])


  return (
    <Offcanvas show={options.show} onHide={() => { handleClose(changed) }} placement={'end'} className="custom-offcanvas">
      <Offcanvas.Header closeButton>
        <div className="row flex-column">
          <div className="col-auto">
            {/* <span style={{ fontSize: '15px' }}>id {options.epic.id}</span> */}
          </div>
          <div className="col-auto">
            <Offcanvas.Title>
              <span style={{ fontSize: '24px' }}>{dictionary.title[lang]} {options.epic.summary}</span>
            </Offcanvas.Title>
          </div>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body style={{ display: 'flex', flexDirection: 'column', paddingBottom: '0' }}>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <div style={{ width: '55%', display: 'flex', flexDirection: 'column', gap: '10px', padding: '16px' }}>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <div style={{ fontSize: '14px', color: 'gray' }}>{dictionary.requirements[lang]}</div>
              <div>
                <div style={{ fontSize: '14px', fontWeight: 'bold' }}>{dictionary.target[lang]}</div>
                <textarea
                  className="editable-textarea"
                  placeholder="Введите текст здесь..."
                  value={projectTarget}
                  onChange={(e) => setProjectTarget(e.target.value)}
                  rows={5}
                />
              </div>
              <div>
                <div style={{ fontSize: '14px', fontWeight: 'bold' }}>{dictionary.tasks[lang]}</div>
                <textarea
                  className="editable-textarea"
                  placeholder="Введите текст здесь..."
                  value={mainTasks}
                  onChange={(e) => setMainTasks(e.target.value)}
                  style={{ height: "calc(100vh - 470px)" }}
                />
              </div>

              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>

                <div style={{ width: '50%' }}>
                  <button
                    style={{ marginTop: '10px', width: '100%' }}
                    className="btn btn-outline-my mb16px"
                    type="button"
                    onClick={() => { saveTechTask(options.epic.id) }}
                  >{dictionary.buttonText[lang]}
                  </button>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                  {/*label*/}

                  <span>{dictionary.lang[lang]}: </span>

                  {/*field*/}
                  <div style={{ marginLeft: '10px' }}>
                    <div className="input-field">
                      <select
                        id="difficulty"
                        name="difficulty"
                        value={lang}
                        className="form-select"
                        onChange={(e) => { setLang(e.target.value) }}
                      >
                        <option value="ru">ru</option>
                        <option value="en">en</option>
                        <option value="es">es</option>
                        <option value="fr">fr</option>
                        <option value="de">de</option>
                      </select>
                    </div>
                  </div>



                </div>


              </div>

            </div>
          </div>
          <div style={{
            width: '45%',
            maxHeight: 'calc(100vh-200px)',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: "#F3F5FC",
            padding: '16px',
            overflowY: 'auto',
            lineHeight: '1.6',
            color: '#333',
          }}>
            <div style={{ fontSize: '15px', fontWeight: 'bold' }}>
              {dictionary.suggestions[lang]}
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  )
}
