import React, { useCallback, useEffect, useState } from 'react'
import { useHttp } from "../../../hooks/http.hook"
import { useMessage } from "../../../hooks/message.hook"
import { useSelector } from 'react-redux'

export const ProjectNotifyPage = () => {
  const auth = useSelector(state => state.auth)
  const id = useSelector(state => state.sidebar.projectId)
  const [form, setForm] = useState({})
  const [addedChannels, setAddedChannels] = useState([])
  const allChannels = ['Telegram', 'Email', 'VK']

  const { request, error, clearError } = useHttp()


  useEffect(() => {

    clearError()
  }, [error, clearError])

  const getProject = useCallback(async (token, id) => {
    try {
      if (token && id) {
        const data = await request('/api/notification/get', 'POST', { projectId: id }, { authorization: 'Bearer ' + token })
        const processedNotifications = {}
        const activeChannels = []

        data.settings.forEach(setting => {
          const { notificationService, ...settings } = setting
          processedNotifications[notificationService] = settings

          // Проверяем, есть ли включенные настройки
          if (Object.values(settings).some(value => value === true)) {
            if (!activeChannels.includes(notificationService)) {
              activeChannels.push(notificationService)
            }
          }
        })

        setForm(processedNotifications)
        setAddedChannels(activeChannels)
      }
    } catch (e) {
      console.log(e)
    }
  }, [request])

  useEffect(() => {
    getProject(auth.token, id)
  }, [getProject, id, auth.token])

  const handleCheckboxChange = useCallback(async (event) => {
    const { name, checked } = event.target
    const [channel, eventType] = name.split('_')

    setForm(prevForm => {
      const updatedChannelSettings = {
        ...prevForm[channel],
        [eventType]: checked
      }

      return {
        ...prevForm,
        [channel]: updatedChannelSettings
      }
    })
  }, [])

  useEffect(() => {
    // Отправляем данные на сервер каждый раз, когда форма обновляется
    const saveSettings = async () => {
      try {
        const response = await request('/api/notification/save', 'POST', {
          projectId: id,
          ...form
        }, { authorization: 'Bearer ' + auth.token })
        // message(response.message);
      } catch (error) {
        console.error(error)
      }
    }

    saveSettings()
  }, [form, id, auth.token, request])

  const addChannel = (channel) => {
    if (!addedChannels.includes(channel)) {
      const updatedChannels = [...addedChannels, channel]
      setAddedChannels(updatedChannels)
    }
  }

  const removeChannel = (channel) => {
    setAddedChannels(addedChannels.filter(c => c !== channel))
  }



  const dictionary = {
    invitedUserHasRegistered: "Приглашенный пользователь зарегистрировался",
    sprintDraftHasBeenCreated: "Создался draft спринта",
    informingAboutStartOfTheSprint: "Информирование о начале спринта",
    communicatingTheEndOfTheSprint: "Информирование о завершении спринта",
    informingAboutTheStartOfStandUpRally: "Информирование о начале проведение stand up митинга",


    reportOnTheListOfTasksTakenIntoTheSprint: "Отчет о списке задач взятых в спринт",
    reportsOnTeamPerformanceAtEndOfTheSprint: "Отчеты по производительности каманды по итогу спринта",
    recommendationsForImprovingTheProcessEndOfTheSprint: "Рекомендации по улучшению процесса по итогу спринта",

    reminderForForgottenTasks: "Напоминание о забытых задачах",
  }

  const notifications = {
    'Уведомления о событиях': [
      "invitedUserHasRegistered",
      "sprintDraftHasBeenCreated",
      "informingAboutStartOfTheSprint",
      "communicatingTheEndOfTheSprint",
      "informingAboutTheStartOfStandUpRally",
      "reportOnTheListOfTasksTakenIntoTheSprint"
    ],
    'Системные отчеты': [
      "reportOnTheListOfTasksTakenIntoTheSprint",
      "reportsOnTeamPerformanceAtEndOfTheSprint",
      "recommendationsForImprovingTheProcessEndOfTheSprint"
    ],
    'Уведомления от нас': [
      "reminderForForgottenTasks"
    ]
  }

  // Фильтрация каналов, которые имеют хотя бы одно включенное поле
  const activeChannels = allChannels.filter(channel => {
    const channelSettings = form[channel]
    return channelSettings && Object.values(channelSettings).some(value => value === true)
  })

  return (
    <>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th scope="col"></th>
              {addedChannels.map(channel => (
                <th scope="col" className="text-center" key={channel}>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div>{channel}</div>
                    <div style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => removeChannel(channel)}>&times;</div>
                  </div>
                </th>
              ))}
              {addedChannels.length < allChannels.length && (
                <th scope="col" className="text-center">
                  <div style={{ width: '135px' }}>
                    <div className="input-field">
                      <select
                        className="form-select select-no-border"
                        onChange={(e) => {
                          addChannel(e.target.value)
                          e.target.value = "" // Сброс значения после выбора
                        }}
                      >
                        <option value="" disabled selected hidden>+ добавить канал</option>
                        {allChannels.filter(channel => !addedChannels.includes(channel)).map(channel => (
                          <option key={channel} value={channel}>{channel}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {Object.entries(notifications).map(([category, events]) => (
              <React.Fragment key={category}>
                <tr>
                  <td colSpan={addedChannels.length + 1}>
                    <h5>{category}</h5>
                  </td>
                </tr>
                {events.map(event => (
                  <tr key={event}>
                    <td>{dictionary[event]}</td>
                    {addedChannels.map(channel => (
                      <td className="text-center" key={channel}>
                        <div className="form-check form-switch" style={{ display: "flex", justifyContent: "center" }}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name={`${channel}_${event}`}
                            checked={form[channel]?.[event] || false}
                            onChange={handleCheckboxChange}
                          />
                        </div>
                      </td>
                    ))}
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}
