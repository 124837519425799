import React, { useCallback, useEffect, useState } from 'react'
import { Offcanvas } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useHttp } from '../../../hooks/http.hook'
import { useJira } from '../../../hooks/jira.hook'
import { useMessage } from '../../../hooks/message.hook'
import { Icon } from '../../partials/Icon'
import { InlineLoader } from '../../partials/InlineLoader'
import { Loader } from '../../partials/Loader'

export const IssueDetails = ({ options, onClose }) => {
  const auth = useSelector(state => state.auth)
  const id = useSelector(state => state.sidebar.projectId)
  const { loading, request, error, clearError } = useHttp()
  const { getTransitions, jiraLoading, jiraConnected, makeTransitions } = useJira()
  const message = useMessage()

  const [comments, setComments] = useState([])
  const [changed, setChanged] = useState(false)
  const [comment, setComment] = useState({
    issueId: '',
    author: '',
    text: '',
  })

  useEffect(() => {
    message(error)
    clearError()
  }, [error, message, clearError])

  const getComments = useCallback(async (token, id) => {
    try {
      if (token && id) {
        const data = await request(`/api/issue/${id}`, 'GET', null, { authorization: 'Bearer ' + token })
        setComments(data.comments)
      }
    } catch (e) {
      console.log(e)
    }
  }, [request])

  useEffect(() => {
    let headerEl = document.querySelector('.offcanvas-header')
    if (headerEl) headerEl.style.paddingBottom = 0
  }, [])

  useEffect(() => {
    (async () => {
      setComment({ ...comment, author: auth.user.name || auth.user.email, issueId: options.task.id })
      await getComments(auth.token, options.task.id)
    })()
  }, [auth.token, options.task.id])



  console.log(' comments[comments.length - 1]?.author', comments[comments.length - 1]?.author)
  console.log('auth.user.name ', auth?.user.emailx)

  const [AItext, setAItext] = useState('')
  const [AItextPromptVisible, setAItextPromptVisible] = useState(false)
  const [AItextPromptChecked, setAItextPromptChecked] = useState(false)

  useEffect(() => {
    (async () => {

      if (comments[comments.length - 1]?.author !== auth?.user?.email) {
        setAItextPromptVisible(true)


        let prompt = `Представь что ты работник службы поддержки по разработке программного обеспечения.\n Тебе присылают тикет, на который необходимо ответить, ты уже знаешь за ранее о какой проблеме идёт речь и в каком программном обеспечении - твоя задача дать ответ, не задавая вопросов и обязательно оказать помощь в решении проблем. Максимум 150 символов.\n ТИКЕТ \n `

        try {




          const gemini = await request('/api/gemini/generateIssue', 'POST', { prompt, comment: comments[comments.length - 1].text }, { authorization: 'Bearer ' + auth.token })


          // const response = await fetch('http://162.248.225.190:5088/generate', {
          //   method: 'POST',
          //   headers: {
          //     'Content-Type': 'application/json',
          //   },
          //   body: JSON.stringify({
          //     text: `${prompt} \n  ${comments[comments.length - 1].text} `,  // Замените на необходимое значение
          //   }),
          // })

          if (!gemini) {
            throw new Error(`Ошибка`)
          }

          // const data = await response.json()
          setAItext(gemini.gemini.text)


          console.log('Ответ от сервера:', gemini.gemini.text)
        } catch (error) {
          console.error('Ошибка при отправке запроса:', error)
        }
      }
      else {
        setAItextPromptVisible(false)
        setAItext('')
      }
    })()
  }, [comments])


  console.log('comments', comments)


  const changeHandler = event => {
    setComment({ ...comment, [event.target.name]: event.target.value })
  }

  const changeHandlerAI = event => {
    setComment({ ...comment, text: AItext })
  }



  const submitComment = async () => {
    try {
      const data = await request('/api/issue/create', 'POST', { comment }, { authorization: 'Bearer ' + auth.token })
      setComment({ ...comment, text: '' })
      message(data.message)
      await getComments(auth.token, options.task.id)
    } catch (e) {
      console.log(e)
    }
  }

  const saveComment = async event => {
    if (event.keyCode === 13) {
      await submitComment()
    }
  }


  const changeStatusHandler = async (e) => {
    if (options && options.task && options.task.id) {
      let k = Object.keys(options.statuses).find((key => options.statuses[key] === e.target.value))

      const transitions = await getTransitions(auth, id, options.task.id)

      let tId = transitions.issueTransitions['transitions'].find(el => el.to.name.toLowerCase() === k)

      const makeTrans = await makeTransitions(auth, id, options.task.id, tId.id)

      setChanged(true)
    }
  }

  useEffect(() => {
    (async () => {
      if (options && options.task && options.task.id) {
        const res = await getTransitions(auth, id, options.task.id)
        console.log(options.task)
        console.log(res)
      }
    })()
  }, [options.task, auth, id])




  const switchHandler = useCallback(async event => {
    setAItextPromptChecked(event.target.checked)


  }, [])



  if (!options.task) {
    return <Loader />
  }






  return (
    <Offcanvas show={options.show} onHide={() => { onClose(changed) }} placement={'end'}>
      <Offcanvas.Header closeButton>
        <div className="row flex-column">
          <div className="col-auto">
            <span style={{ fontSize: '15px' }}>id {options.task.id}</span> <span className="text-gray" style={{ fontSize: '13px' }}>от {new Date(options.task.fields.created).toLocaleDateString('ru-RU', {
              year: 'numeric',
              month: 'short',
              day: 'numeric'
            })}</span>
          </div>
          <div className="col-auto">
            <Offcanvas.Title><span style={{ fontSize: '24px' }}>{options.task.summary}</span></Offcanvas.Title>
          </div>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body style={{ display: 'flex', flexDirection: 'column', paddingBottom: '0' }}>

        <span style={{ fontSize: '15px' }} className="text-gray">{options.task.description ? options.task.description.content.map(c1 => c1.content.map(c2 => c2.text).join(' ')).join('\n') : 'Нет описания'}</span>
        <div className="row mt-4" style={{ fontSize: '15px' }}>
          <div className="col-4">Проект:</div>
          <div className="col-8"><Icon name="Tag" size="16px" />{options.task.parent ? options.task.parent.fields.summary : '-'}</div>
        </div>

        {/*<div className="row mt-2 mb-3" style={{fontSize: '15px'}}>*/}
        {/*  <div className="col-4">Статус:</div>*/}
        {/*  <div className="col-8">{options.statuses[options.task.status.name.toLowerCase()] || ''}</div>*/}
        {/*</div>*/}

        <div className="row mt-2 mb-3" style={{ fontSize: '15px' }}>
          <div className="col-4 align-self-center">Статус:</div>
          <div className="col-8">
            <div className="input-field">
              <select
                id="type"
                name="status"
                defaultValue={options.statuses[options.task.status.name.toLowerCase()]}
                className="form-select"
                onChange={changeStatusHandler}
              >
                {options.statuses && Object.keys(options.statuses).length ? Object.keys(options.statuses).map(st => {
                  return (
                    <option value={options.statuses[st]} key={st}>{options.statuses[st]}</option>
                  )
                }) : <option value="">-</option>}
              </select>
            </div>
          </div>
        </div>

        <span style={{ fontSize: '18px' }}>Комментарии</span>

        <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflowY: 'auto', scrollbarWidth: 'none', msOverflowStyle: 'none' }}>
          {!loading && comments.length ? (
            <div className="mt-3"
              style={{

                flexGrow: 1,
                // overflowY: 'auto',
                scrollbarWidth: 'none', // скрываем полосу прокрутки для Firefox
                msOverflowStyle: 'none', // скрываем полосу прокрутки для IE и Edge
              }}

            >
              {comments.map((c) => {
                return (
                  <div className="row mb-2 align-items-start" key={c.id}>
                    <div className="col-1 me-2">
                      <div
                        style={{
                          width: '32px',
                          height: '32px',
                          borderRadius: '50%',
                          background: '#C4C4C4',
                          marginTop: '5px',
                        }}
                      />
                    </div>
                    <div className="col d-flex flex-column">
                      <span style={{ fontSize: '15px', fontWeight: '600', flexShrink: 0 }}>{c.author}</span>
                      <span style={{ fontSize: '15px', fontWeight: '400', flexShrink: 0, wordBreak: 'break-word' }}>
                        {c.text}
                      </span>
                      <span style={{ fontSize: '13px', fontWeight: '400', flexShrink: 0 }}>
                        {new Date(c.createdAt).toLocaleDateString('ru-RU', {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                        })}
                      </span>
                    </div>
                  </div>
                )
              })}
            </div>
          ) : (
            loading && <InlineLoader size={20} ml={1} />
          )}
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 0 }}>
          {AItextPromptVisible && AItextPromptChecked ?
            <div className="input-field mt-2">
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '4px',
              }}>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '2px',
                  fontSize: '15px',
                  backgroundColor: '#F3F5FB',
                  padding: '6px 16px',
                }}>
                  <div style={{ fontWeight: '600' }}>Подсказка</div>
                  <div>{AItext}</div>
                </div>
                <div onClick={changeHandlerAI} className="ai-button">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 7.50001H10V4.16667L15.8333 10L10 15.8333V12.5H5V7.50001Z" stroke="#3D69A4" strokeWidth="0.833333" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
              </div>
            </div>

            :
            <></>
          }

          <div className="input-field mt-3">
            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
              <textarea
                id="text"
                className="form-control"
                name="text"
                placeholder="Добавить комментарий"
                value={comment.text}
                onChange={changeHandler}
                onKeyDown={saveComment}
                style={{
                  height: 'auto',
                  resize: 'none',
                  boxSizing: 'border-box',
                  paddingRight: '40px',
                  width: '100%',
                  overflowY: 'auto', // включаем прокрутку по вертикали
                  scrollbarWidth: 'none', // скрываем полосу прокрутки для Firefox
                  msOverflowStyle: 'none', // скрываем полосу прокрутки для IE и Edge
                  minHeight: '80px', // устанавливаем минимальную высоту
                  lineHeight: '20px', // задаем высоту строки
                }}
                onInput={(e) => {
                  e.target.style.height = 'auto'
                  e.target.style.height = `${e.target.scrollHeight}px`
                }}
              />
              <button
                type="button"
                onClick={submitComment}
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  background: 'none',
                  border: 'none',
                  padding: 0,
                  cursor: 'pointer',
                }}
              >
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M15.1597 15.9583L18.988 12.2627C19.3371 11.9255 19.3376 11.35 18.988 11.0123L15.1597 7.31636C14.6368 6.81138 13.7812 7.19635 13.7812 7.94152V10.2156H4.48438V7.23055C4.48438 7.11742 4.44116 7.00892 4.36423 6.92893L2.45017 4.93831C2.19181 4.66958 1.75 4.85989 1.75 5.23993V12.2063C1.75 12.6774 2.11726 13.0594 2.57031 13.0594L13.7812 13.0594V15.3332C13.7812 16.0753 14.6347 16.4654 15.1597 15.9583Z" fill="black" fillOpacity="0.25" />
                  <g mask="url(#mask0_117_21215)">
                    <rect x="21" width="21" height="21" transform="rotate(90 21 0)" fill="#FFFFFF" fillOpacity="0.25" />
                  </g>
                </svg>
              </button>
            </div>
          </div>


          <div style={{ display: 'flex', justifyContent: 'start', width: '100%', marginTop: '4px' }}>
            <div className="col d-flex " style={{ display: 'flex', alignContent: 'center' }}>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="notifyTelegram"
                  checked={AItextPromptChecked}
                  onChange={switchHandler}
                />
              </div>
              <div>Подсказки</div>
            </div>
          </div>

        </div>


      </Offcanvas.Body>
    </Offcanvas>
  )
}